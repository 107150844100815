import { graphql } from 'gatsby';
import React from 'react';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import Bragi from '../src/components/Bragi/bragi';
import { Freya } from '../src/components/Freya/Freya';
import { Fulla } from '../src/components/Fulla/Fulla';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hod from '../src/components/Hod/Hod';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { Odin } from '../src/components/Odinv2/Odin';
import TitleH3 from '../src/components/Titles/TitleH3/TitleH3';
import buildFunnelUrl from '../src/helpers/buildFunnelUrl';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import './promotion_boxing_day.scss';

const PromotionsDetails = (props) => {
  let fullaData;
  let fullaDataMobile;

  const heimdallData = {
    name: props.data.allPromocionesTitleBlockBoxing.edges[0].node.title,
    subtitle: props.data.allPromocionesTitleBlockBoxing.edges[0].node.subTitle,
    image: {
      url: props.data.allPromocionesBlockImageInfoGeneralBoxing.edges[0].node.localImage
        .childImageSharp.fluid,
      alt: props.data.allPromocionesBlockImageInfoGeneralBoxing.edges[0].node.alt,
    },
    imageMobile: {
      alt: props.data.allPromocionesMobileTitleBlockBoxing.edges[0].node.alt,
      url: props.data.allPromocionesMobileTitleBlockBoxing.edges[0].node.localImage.childImageSharp
        .fluid,
    },
  };

  const odinData = {
    image: props.data.allPromocionesEventBlockBoxing.edges[0].node.localImage.childImageSharp.fluid,
    alt: props.data.allPromocionesEventBlockBoxing.edges[0].node.alt,
    title: props.data.allPromocionesEventBlockBoxing.edges[0].node.event_name,
    rssImg: true,
    buttons: {
      cta1: props.data.allPromocionesEventBlockBoxing.edges[0].node.call_to_action_path,
      ctaText1: props.data.allPromocionesEventBlockBoxing.edges[0].node.call_to_action_copy,
      size: 'alone',
      color1: 'orange',
    },
  };

  fullaData = {
    titleText: props.data.allPromocionesContentMultiColumnBlockLeftBoxing
      ? props.data.allPromocionesContentMultiColumnBlockLeftBoxing.edges[0].node.title
      : null,
    fullaBody: props.data.allPromocionesContentMultiColumnBlockLeftBoxing
      ? props.data.allPromocionesContentMultiColumnBlockLeftBoxing.edges[0].node.body_left
      : null,
    buttons: {
      cta1: buildFunnelUrl(props.pageContext.locale, 'hotels'),
      ctaText1: 'Book',
      size: 'alone',
      color1: 'orange',
    },
    secondaryTitleText: props.data.allPromosFullaBlockBoxing.edges[0].node.title,
    secondaryfullaBody: props.data.allPromosFullaBlockBoxing.edges[0].node.body,
  };

  fullaDataMobile = {
    titleText: props.data.allPromocionesContentMultiColumnBlockLeftBoxing
      ? props.data.allPromocionesContentMultiColumnBlockLeftBoxing.edges[0].node.title
      : null,
    titleType: props.pageContext.pageName === 'noche-italiana' && 'H2',
    fullaBody: props.data.allPromocionesContentMultiColumnBlockLeftBoxing
      ? props.data.allPromocionesContentMultiColumnBlockLeftBoxing.edges[0].node.body_left
      : null,
    buttons: {
      cta1: buildFunnelUrl(props.pageContext.locale, 'hotels'),
      ctaText1: 'Book',
      size: 'alone',
      color1: 'orange',
    },
    secondaryTitleText: props.data.allPromosFullaBlockBoxing.edges[0].node.title,
    secondaryfullaBody: props.data.allPromosFullaBlockBoxing.edges[0].node.body,
  };

  const freyaData = {
    title: tt('PROMOCIONES RELACIONADAS', props.pageContext.locale),
    cards: props.data.allPromocionesPromosSubBlockBoxing.edges.map((item) => ({
      image: item.node.localImage.childImageSharp.fluid,
      altImage: item.node.alt,
      title: item.node.title,
      imgsize: 'promoted',
      buttons: {
        cta: item.node.buttons.cta,
        size: item.node.buttons.size,
        color: item.node.buttons.color,
        ctaText: item.node.buttons.ctaText,
      },
    })),
  };

  const isMobileOdin = (
    <>
      <Odin data={odinData} locale={props.pageContext.locale} />
      <Fulla context={props.pageContext.group_name} data={fullaDataMobile} />
    </>
  );
  const isDesktopOdin = (
    <>
      <Odin data={odinData} locale={props.pageContext.locale} />
      <Fulla context={props.pageContext.group_name} data={fullaData} />
    </>
  );

  const titleData = {
    title: tt('OTRAS PROMOCIONES', props.pageContext.locale),
  };

  return (
    <Layout
      layoutFromOld
      locale={props.pageContext.locale}
      pageName={props.pageContext.pageName}
      options={props.pageContext.options}
    >
      <SEO
        title={props.data.allPromocionesSeoDataBoxing.edges[0].node._0.title}
        description={props.data.allPromocionesSeoDataBoxing.edges[0].node._1.description}
        imageRich={props.data.allFichaPromosRichDataBoxing.edges[0].node.rich_markup.imgRich}
        pathname={props.pageContext.url}
        lang={props.pageContext.locale}
      />
      <div className="is-fullWidth">
        <Heimdall data={heimdallData} />
        <div className="promotions-details-content">
          <NeoAesir
            locale={props.pageContext.locale}
            pageName={props.pageContext.pageName}
            options={props.pageContext.options}
            title={props.data.allFichaPromosBreadCrumbBlockBoxing.edges[0].node.name}
          />
          <Hod
            data={props.data.allFichaPromosRichDataBoxing.edges[0].node.rich_markup}
            url={props.pageContext.url}
            img={props.data.allFichaPromosRichDataBoxing.edges[0].node.imgRich}
          />
          <BlueArrow />
          <MediaServerRender predicted="mobile" hydrated>
            <MediaMatcher mobile={isMobileOdin} tablet={isMobileOdin} desktop={isDesktopOdin} />
          </MediaServerRender>
          {props.data.fichaPromosVideoBoxing && (
            <div className="video-container">
              <Bragi url={props.data.fichaPromosVideoBoxing.video} />
            </div>
          )}
          <div className="freya-prom-container">
            <TitleH3 data={titleData} />
            <Freya data={freyaData} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

PromotionsDetails.propTypes = {};

export default PromotionsDetails;

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query allFichaPromocionQueryBoxing($locale: String!) {
    allPromocionesTitleBlockBoxing(
      filter: { tag: { eq: "promotion_boxing_day" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          title
          subTitle
          description
        }
      }
    }
    allPromocionesSeoDataBoxing(
      filter: { tag: { eq: "promotion_boxing_day" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allPromocionesBlockImageInfoGeneralBoxing(
      filter: { tag: { eq: "promotion_boxing_day" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPromocionesMobileTitleBlockBoxing(
      filter: { tag: { eq: "promotion_boxing_day" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPromocionesEventBlockBoxing(
      filter: { tag: { eq: "promotion_boxing_day" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(maxWidth: 1200, maxHeight: 600, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          call_to_action_copy
          call_to_action_path
          event_name
        }
      }
    }
    allPromocionesContentMultiColumnBlockLeftBoxing(
      filter: { tag: { eq: "promotion_boxing_day" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          title
          body_left
        }
      }
    }
    allPromocionesPromosSubBlockBoxing(
      filter: { tag: { eq: "promotion_boxing_day" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          title
          description
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imgsize
          stars
          buttons {
            cta
            size
            color
            ctaText
          }
          description
        }
      }
    }
    allPromosFullaBlockBoxing(
      filter: { tag: { eq: "promotion_boxing_day" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          tag
          title
          body
        }
      }
    }
    allFichaPromosBreadCrumbBlockBoxing(
      filter: { tag: { eq: "promotion_boxing_day" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allFichaPromosRichDataBoxing(
      filter: { tag: { eq: "promotion_boxing_day" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
            imgRich
            price
            date_valid_until
            date_valid_start
          }
        }
      }
    }
  }
`;
